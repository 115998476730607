const officers = [
    {
        title: "President",
        name: "Alan Copeland",
        phone: "(918) 361-9650, (918)-245-7595",
        email: "acopeland@shelterinsurance.com"
    },
    {
        title: "Vice President/Treasurer",
        name: "Jeff Ammons",
        phone: "(806) 664-1133",
        email: "jammons@sbcglobal.net"
    },
    {
        title: "Secretary",
        name: "Paula West",
        phone: "(936) 414-2677",
        email: "waulapest1@yahoo.com"
    },
    {
        title: "Board Member",
        name: "Will Lane",
        phone: "(817) 734-6385",
        email: "will09lane@gmail.com"
    },
    {
        title: "Board Member",
        name: "Cindy Jones",
        phone: "(806) 438-3345",
        email: "cindy57mtn@gmail.com"
    },
    {
        title: "Board Member",
        name: "Gary Giarratano",
        phone: "(720) 215-8238",
        email: "gary80005@gmail.com"
    },
    {
        title: "Board Member/Roads",
        name: "Tom Russ",
        phone: "(719) 240-3093  ",
        email: "tomruss1008@gmail.com"
    }
]

const waterOfficers = [
    {
        name: "Scott Ford",
        title: "Water Man",
        phone: "(562) 458-5302 (Texts and Calls)",
        email: "sfnotecorp@gmail.com"
    }
]


const security = [
    {
        name: " Ken Pfalmer",
        title: "Winter Security",
        phone: "(719) 846-4126 or (719) 680-4127",
        email: "kenneth.pfalmer@hotmail.com",
        description: "If you have a problem or concern over the winter please contact him."
    }
]

const architecturalCommittee = [
    {
        name: "Paula West",
        phone: "(936) 414-2677",
        email: "waulapest1@yahoo.com"
    },
    {
        name: "Wally Munden",
        phone: "(417) 865-0584",
        email: "wmunden2@sbcglobal.net"
    },
    {
        name: "Cindy Jones",
        phone: "(806) 438-3345",
        email: "cindy57mtn@gmail.com"
    },
        {
        name: "Colleen Russ",
            phone: "(719) 250-6659",
        email: "stcbaruss@msn.com"
    }
]

const legal = [
    {
        name: 'Wilklow & Associates',
        title: 'Accountant',
        website: 'https://wilklowassociates.com/contact/',
        imgSource: false,
        description: 'Our Accountant is Wilklow & Associates. They keep our financial records and membership. If you have questions about these things please contact them. If you have a change in your contact information please notify them and Henry Ford (henryford1946@me.com) to update the information.'
    }
]

const personBlock = (options) => {
    let { title, name, phone, email, description, imgSource,address,website } = options
    if (!imgSource && imgSource !== false) {
        imgSource = 'https://t4.ftcdn.net/jpg/03/46/93/61/360_F_346936114_RaxE6OQogebgAWTalE1myseY1Hbb5qPM.jpg'
    }
    return <div className='flex-row sploa-officer-line '>
        {/*{imgSource ? < img className='image-size' src={imgSource}></img> : <></>}*/}
        <div>
            <div className='bold primaryColor large'> {name} </div>
            <div className='bold grey medium'> {title} </div>
            {phone ? <div> <span className='bold grey small'> Phone: </span> <span>{phone} </span> </div> : <></>}
            {email ? <div><span className='bold grey small'>  Email: </span>{email} </div> : <></>}
            {address ? <div><span className='bold grey small'>  Address: </span>{address} </div> : <></>}
            {website ? <div><span className='bold grey small'>  Website: </span><a href={website}> {website}</a> </div> : <></>}
            {description ? <div>{description} </div> : <></>}

        </div>
    </div>
}



export default function Rules() {
    return (
        <>
            <div class="sploa-info-page">

                <div className='sploa-flex-center sploa-title-header'></div> <br/>


                <div className='sploa'>
                    <div>
                        <div className='sploa-header'>
                               Officers 
                        </div>
                        {officers.map(officer => {
                            return personBlock(officer)
                            
                        })}
          

                        <div>
                            <div className='sploa-header-2'>Water Man:</div>
                            {waterOfficers.map(officer => {
                                return personBlock(officer)

                            })}
                        </div>

                        <div>
                            <div className='sploa-header-2'>Security</div>
                            {security.map(officer => {
                                return personBlock(officer)

                            })}
                        </div>

                        <div>
                            <div className='sploa-header-2'> Architectural Committee</div><div className="sploa-officer-line"> The members of the Association must consult and have the approval of this committee. No dwelling or other improvement shall be erected, placed or altered on any premises on said property until a plot plan and building plans and specifications have been approved in writing by the Architectural Committee. A copy of our permit is on our website under Official Docs. The instructions are there too. </div>
                            <div className="sploa-officer-line"> The Architectural committee consists of: </div>
                            {architecturalCommittee.map(officer => {
                                return personBlock(officer)

                            })}
     

                        </div>
                        <div>
                            <div className='sploa-header-2'> Legal </div><div className="sploa-officer-line"> </div>
                                {legal.map(officer => {
                                    return personBlock(officer)

                                })}
                        </div>
                    </div>
                </div> <br/>
            </div> <br/>
        </>
    );
}