import {
    Spinner,
    UncontrolledCarousel
  } from 'reactstrap';
  import React, { useState } from 'react';
import getClosestFutureFirstSaturday  from '../utils/utils'

  import {
    Link
  } from "react-router-dom";
import logo from '../assets/sploaSpring1.png'
export default function Home() {
    const [pictureLoaded, setPictureLoaded] = useState(false)

    const generateRandomPicture = () => {
        const picMaxes = {
            spring:1,
            summer:1,
            fall:1,
            winter:2
        }
    
        const seasons = Object.keys(picMaxes);
        const randomSeasonIndex = Math.floor(Math.random() * seasons.length);
        const selectedSeason = seasons[randomSeasonIndex];

        const max = picMaxes[selectedSeason];

        // Step 3: Generate a random number within the range of 1 to max
        const randomNum = Math.floor(Math.random() * max) + 1;
        return "sploa" + selectedSeason + randomNum;
    }
    return (
        <>
        
            <div class="sploa-home-main">
      
            <img  style={pictureLoaded ? {} : {display: 'none'}} onLoad={() => setPictureLoaded(true) } src={logo} alt="Logo" className='sploa-header-image'/>

                {/* <UncontrolledCarousel
                    className='sploa-home-carousel'
                    items={[
                        {
                            altText: '',
                            caption: '',
                            key: 1,
                            src: 'https://picsum.photos/id/123/1200/600'
                        },
                        {
                            altText: '',
                            caption: '',
                            key: 2,
                            src: 'https://picsum.photos/id/456/1200/600'
                        },
                        {
                            altText: '',
                            caption: '',
                            key: 3,
                            src: 'https://picsum.photos/id/678/1200/600'
                        }
                    ]}
                /> */}
                {/*<div className='sploa-home-important'> <b> The next SPLOA Board Meeting is set for {getClosestFutureFirstSaturday()} at 10:00 a.m. in the Community Building.  Our meetings are set on the first Saturday of the month from June through September.  Our last meeting this season (General Meeting) will be August 31, since that falls on Labor Day Weekend.  </b> </div >*/}
                <div className='sploa-home-important'> <b> The next SPLOA Board Meeting is set for Saturday, June 7th 2025 at 10:00 a.m. in the Community Building.  Our meetings are set on the first Saturday of the month from June through September.  Our last meeting this season (General Meeting) will be August 30, since that falls on Labor Day Weekend.  </b> </div >

                {/* <div className='info-header'>ADD TO FORM NAV BAR: We have worked out a formal arrangement for the use of our community building. Any member interested in using the building for a personal family event will need to fill out the <a href="http://sploa.org/importantinforma.html">usage form</a> and following the instructions.</div >
                <br />

                <div className='info-header'>MOVE TO IMPORTANT INFO: The U.S. Forest Service wants our membership to be aware of rules being implemented now and in the future regarding the San Isabel National Forest. Please make yourself familiar with this information by clicking <a href="http://sploa.org/importantinforma.html">here.</a></div >
                <br /> */}
                <div className='info-header'>If you are at the association the third Saturday of June, July or August, please join us for a Potluck Luncheon/Social at 1:30 p.m. at the Community Building!  Hope to see you there! </div >
                <div className='info-header'>If you are interested/willing to run for the SPLOA Board, please contact Paula West (Officers Page).</div >


                <div className='sploa-home-important'> It is imperative that we stay off of Twin Peaks land. They have signs and cameras and any trespassers will receive citations for class four felonies.</div >
                <br />
                <div className='info-header'> Please make sure our winter security man (Officers page) knows how to contact you in case of an emergency.</div >

                <br />
                <div className='info-header'> Any contact information changes needs to be sent to nancy@wilklowassociates.com, our accounting firm, and to the board secretary.</div >

                <br />

                <div className='sploa-home-info-subtext'> This site is intended to serve SPLOA members as a central source for dispensing and sharing information including newsletters, for-sale notices and bylaws. We will post photos and links to other sites with information pertinent to SPLOA members. If you have any comments, suggestions, photos, for-sale ads, etc. please notify a board member. We are also looking for SPLOA and Spanish Peaks historical information to put in the SPLOA history section.</div> <br />
                <div className='sploa-home-info-subtext'> Web Developer: Kyle Ford (kyleaubreyford@gmail.com) </div>
                <div className='sploa-home-info-subtext'> Last revised: July 1st, 2024</div>

            </div>
        </>
    )
}